import mixpanel from 'mixpanel-browser';
import { isLocalhost, isStaging, isDemo } from './axios-instance';

var isDebugEnv = isLocalhost || isStaging || isDemo;
if (isDebugEnv === undefined) {
  console.error('isDebugEnv is undefined! This should never happen. Did you do a circular import?');
}
const prodMixpanelToken = "0e0e3561aa5728a44ba03868fc42a18b";
mixpanel.init(isDebugEnv ? '' : prodMixpanelToken, { debug: isDebugEnv, track_pageview: !isDebugEnv, persistence: 'localStorage' });

let actions = {
  identify: (id: string) => {
    if (!isDebugEnv) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (!isDebugEnv) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (!isDebugEnv) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (!isDebugEnv) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
