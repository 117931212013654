import React from 'react';
import './Security.css';
import VantaHIPAA from './Assets/VantaHIPAA.png';

interface SecurityProps {
    openCalendly: () => void;
}

const Security: React.FC<SecurityProps> = ({ openCalendly }) => {
    return (
        <div className='Security'>
            <div className='Title' id="Security">HIPAA Compliance</div>
            <div className='SecuritySubtitle'>Your patients’ and organization’s security is our top priority. We use Vanta to monitor our technical infrastructure and follow best practices to maintain compliance.</div>
            <img className='VantaIcon' src={VantaHIPAA} alt="Vanta HIPAA Icon" />
            <div className='LearnMoreButton' onClick={openCalendly}>Learn More</div>
        </div>
    )
}

export default Security;
